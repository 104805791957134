import debounce from "lodash/debounce";
import querystring from "querystring";
import { useCallback, useState } from "react";

import logger from "../../../utils/logger";
import useQuery from "../useQuery";
import { IndustryQuery, IndustryQueryVariables } from "./__generated__/types";
import INDUSTRY_QUERY from "./industryQuery.gql";
import mapIndustryOrSynonymToVisibleItem, {
  VisibleItem,
} from "./mapIndustryOrSynonymToVisibleItem";

const UNKNOWN_INDUSTRY = "__UNKNOWN_INDUSTRY__";

export type EntryRouterResult = {
  path: string;
  maskedPath?: string;
};

export type UseIndustrySearchArgs = {
  allowSubmitWithoutSelection?: boolean;
  backendUrl: string;
  messages: {
    errorMessageEmptyInput: string;
    errorMessageEntryRouter: string;
    notFound: (inputValue: string) => string;
  };
  onNavigation?: (entryRouterResult: EntryRouterResult) => void;
  onIndustrySelection?: (industryId: string | undefined) => void;
  selectedProductTypeIds?: string[];
  selectedProductTypeCombination?: string;
  tenant: string;
};

const useIndustrySearch = ({
  allowSubmitWithoutSelection,
  backendUrl,
  messages,
  onNavigation,
  onIndustrySelection,
  selectedProductTypeIds,
  selectedProductTypeCombination,
  tenant,
}: UseIndustrySearchArgs) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState("");
  const { loading, error, data, refetch } = useQuery<
    IndustryQuery,
    IndustryQueryVariables
  >(`${backendUrl}/graphql`, INDUSTRY_QUERY, {
    variables: { query: inputValue },
  });

  const debouncedRefetch = useCallback(
    debounce((variables: IndustryQueryVariables) => {
      refetch(variables);
    }, 300),
    [],
  );

  const getVisibleItems = (): VisibleItem[] => {
    if (error || !data || !data.searchIndustries) {
      return [];
    }

    const industriesAndSynonyms = data.searchIndustries.industriesAndSynonyms
      .map(mapIndustryOrSynonymToVisibleItem)
      .filter(Boolean) as VisibleItem[];

    if (loading && industriesAndSynonyms.length === 0) {
      // While the query is still loading we don't want to show the
      // "not found"-option on its own.
      return [];
    }

    if (inputValue === "") {
      return industriesAndSynonyms;
    }

    return [
      ...industriesAndSynonyms,
      tenant === "sales"
        ? {
            id: "2",
            industryId: "2",
            label: "Sonstige",
            labelWithoutHighlights: "Sonstige",
          }
        : {
            id: UNKNOWN_INDUSTRY,
            industryId: "",
            label: messages.notFound(inputValue),
            labelWithoutHighlights: "",
          },
    ];
  };

  const routeToNextPage = async (industryId: string | undefined) => {
    const queryParametersForEntryRouter = {
      // TODO: remove the `agent` query parameter once it is no longer required
      agent: tenant,
      returnRedirectUrl: true,
      target: "interview",
      tenant,
      ...(industryId ? { industryId } : {}),
      ...(selectedProductTypeCombination
        ? { selectedProductTypeCombination }
        : {}),
      ...(selectedProductTypeIds ? { selectedProductTypeIds } : {}),
    };

    try {
      const result = await fetch(
        `${backendUrl}/0.3/entry-router?${querystring.stringify(
          queryParametersForEntryRouter,
        )}`,
        { credentials: "include" },
      );

      if (!result.ok) {
        logger({ status: "error", message: result.statusText });
        setErrorMessage(messages.errorMessageEntryRouter);
        return;
      }

      const entryRouterResult = await result.json();
      onNavigation?.(entryRouterResult);
    } catch (err) {
      logger({ status: "error", message: err });
      setErrorMessage(messages.errorMessageEntryRouter);
    }
  };

  const handleInputValueChange = (newInputValue: string) => {
    setInputValue(newInputValue);

    if (errorMessage && newInputValue !== "") {
      setErrorMessage(null);
    }

    debouncedRefetch({ query: newInputValue || "" });
  };

  const handleItemSelection = (selectedItem: VisibleItem | null) => {
    if (!selectedItem) {
      return;
    }
    setInputValue(selectedItem.labelWithoutHighlights);
    onIndustrySelection?.(selectedItem.industryId);
    if (onNavigation) {
      if (selectedItem.id === UNKNOWN_INDUSTRY && inputValue) {
        onNavigation({
          path: `/${tenant}/industry-not-found?query=${encodeURIComponent(
            inputValue,
          )}`,
        });
      } else {
        routeToNextPage(selectedItem.industryId);
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const [firstVisibleIndustry] = getVisibleItems();

    if (firstVisibleIndustry || allowSubmitWithoutSelection) {
      setInputValue(firstVisibleIndustry?.labelWithoutHighlights || "");
      setErrorMessage(null);

      onIndustrySelection?.(
        firstVisibleIndustry ? firstVisibleIndustry.industryId : undefined,
      );
      routeToNextPage?.(
        firstVisibleIndustry ? firstVisibleIndustry.industryId : undefined,
      );
    } else {
      setErrorMessage(messages.errorMessageEmptyInput);
    }
  };

  const hasError = Boolean((error && inputValue !== "") || errorMessage);

  return {
    errorMessage,
    getVisibleItems,
    handleInputValueChange,
    handleItemSelection,
    handleSubmit,
    hasError,
    inputValue,
    loading,
  };
};

export default useIndustrySearch;
